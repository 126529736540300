@font-face {
  font-family: Gotham;
  src: url(./fonts/Gotham-Black.woff2);
  font-weight: 900;
}

@font-face {
  font-family: Gotham;
  src: url(./fonts/GothamMedium.woff2);
  font-weight: normal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-outer-section{
  display: flex;
  justify-content: center;
  width: 1180px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.App-hero-section {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.App-section{
  background-color: #282c34;
  width: 100%;
  
  padding: 80px 0;
}

.App-link {
  color: #61dafb;
}

.isWhite {
  color: #fff !important;
}

.isBlack {
  color: #000 !important;
}

.activeNavBtn{
  background-color: #faa41b !important;
  color: #fff !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.activeNavBtnMobile{
  background-color: #faa41b !important;
  color: #ffffff !important;
}

.active .mobileMenuItem{
  background-color: #faa41b;
  color: #fff;
}

.active button,
.active button:hover{
  color: #000 !important;
  background-color: #faa41b;
}

.active .disciplineBox{
  background-color: #faa41b;
  color: #fff;
}

.active .disciplineBox .disciplineBoxLinkText{
  display: none;
}

.navLink{
  color: #000;
}

a{
  text-decoration: none;
}

#map {
  height: 400px;
  width: 100%;
}

.disciplineList{
}

.disciplineList li::before{
  content: "\2022"; 
  color: #faa41b; 
  font-weight: bold;
  position: absolute;
  display: block;
  width: 1em; 
  top: 8px;
  left: 1px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
